/*! noscript.scss
 * For any adjustments if JS is inactive / not available
 * Per Media Type */

 // Configuration and helpers
@import 'main-bracket/abstracts/_variables';

 @media print 
 {
    html body #page #content 
    {
        a[href]
        {
            display: inline-block;
            position: relative;
        }
        
        .insight__columns__content 
        {
            h1.h1.title:before { display:block; }
        }
        
        > a.insight_info__left
        {
            .author_image:before,
            .insight_info__multiple > .row:first-child:after
            { 
                content: var(--qrcode_request) "\A \00a0 \A " var(--authorString);
                white-space:pre-wrap;
            }

            &[href]:has( > .author_info ) 
            { 
                &:after { padding-block-end: 1rem; }

                > .author_info { padding-bottom: 0!important; }
            }
        }

        > .insight a[href]:has( > .author_info ) 
        { 
            &:after { padding-block-end: 1rem; }

            > .author_info { padding-bottom: 0!important; }
        }
    }
}